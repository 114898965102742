<template>
	<div>
		<test></test>
		<img src="./images/banner6@1x.png" style="width: 100%;" />

		<div class="cont_detail">
			<div class="new_detail">
				<p><router-link to='/Notice'>公告新闻</router-link>>行业新闻</p>
				<h3>{{detail.contentTitle}}</h3>
				<span>{{detail.contentDatetime.slice(0,10)}}</span><span style="margin-left: 40px;" v-if="detail.contentSource">来源：{{detail.contentSource}}</span>
				<span v-else></span>
				<div class="new_cont" v-html="detail.contentDetails">
					
				</div>
			</div>			
			<foot></foot>
		</div>
		
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
	data(){
		return{
			id:'',
			detail:'',
		}
		
	},
	created() {
		var that = this
		this.id = this.$route.query.id
		console.log(this.id)
		
		this.$axios.get('/cms/content/get.do',{
			params:{            
				id:this.id
			}
				 
		}).then(function (res) {
				console.log(res);
				that.detail = res.data.data
			 
		})
	}
}
</script>

<style>
	.cont_detail{
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
	}
	.cont{
		height: 1200px;
	}
	.new_detail a{
		color: #888;
	}
	.new_detail{
		/* height: 1200px;
		overflow-y: scroll; */
		width: 1024px;
		padding: 30px 80px;		
		margin: 0 auto 30px auto;
		background-color: #fff;
		box-shadow: 0 0 20px #9d9d9d;
	}
	.new_detail p{
		text-align: left;
		font-size: 14px;
		color: #888;
	}
	.new_detail h3{
		font-weight: normal;
		margin: 20px 0;
	}
	.new_detail span{
		font-size: 12px;
		color: #a1a1a1;
		/* display: block; */
		margin-bottom: 20px;
	}
	.new_cont{
		/* height: 1000px;
		overflow-y: scroll; */
		font-size: 14px;
		color: #333;
	}
</style>
